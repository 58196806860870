
import {
  IonBadge,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonMenuButton,
  IonPage,
  IonThumbnail,
  IonTitle,
  IonSearchbar,
  IonToolbar,
  modalController,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { hourglass } from "ionicons/icons";
import { useRouter } from "vue-router";
import BackgroundJobModal from "./background-job-modal.vue";
import { useStore } from "@/store";
import { mapGetters } from "vuex";
import Image from '@/components/Image.vue';

export default defineComponent({
  name: "settings",
  components: {
    IonBadge,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonItem,
    IonLabel,
    IonIcon,
    IonMenuButton,
    IonPage,
    IonThumbnail,
    IonTitle,
    IonSearchbar,
    IonToolbar,
    Image
  },
  data() {
    return {
      queryString: '',
      orderIdentificationTypeId: process.env.VUE_APP_ORD_IDENT_TYPE_ID,
      orderedAfter: '',
      orderedBefore: '',
      selectedItems: [] as any,
      hasQuery:  false
    }
  },
  computed: {
    ...mapGetters({
      products: 'product/getList',
      isScrolleable: 'product/isScrolleable',
      getProductStock: 'stock/getProductStock',
      getProduct: 'product/getProduct',
      isJobPending: 'job/isJobPending'
    })
  },
  methods: {
    async loadMoreProducts(event: any) {
      this.getProducts(
        undefined,
        Math.ceil(this.products.length / process.env.VUE_APP_VIEW_SIZE).toString()
      ).then(() => {
        event.target.complete();
      })
    },
    async getProducts( vSize?: any, vIndex?: any) {
      const viewSize = vSize ? vSize : process.env.VUE_APP_VIEW_SIZE;
      const viewIndex = vIndex ? vIndex : 0;
      const payload = {
        viewSize,
        viewIndex,
        queryString: '*' + this.queryString + '*',
        groupByField: 'parentProductId',
        groupLimit: 0,
        filters: JSON.parse(process.env.VUE_APP_ORDER_FILTERS)
      }
      return this.store.dispatch("product/findProducts", payload).finally(() => {
        this.hasQuery = true;
      })

    },
    async openActiveJobs() {
      const bgjobmodal = await modalController.create({
        component: BackgroundJobModal,
        cssClass: "my-custom-class",
      });
      return bgjobmodal.present();
    },
    selectSearchBarText(event: any) {
      event.target.getInputElement().then((element: any) => {
        element.select();
      })
    }
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    return {
      router,
      store,
      hourglass,
    };
  },
});


import { 
  IonButtons,
  IonButton,
  IonContent,
  IonFab,
  IonFabButton,
  IonHeader,
  IonItem,
  IonIcon,
  IonLabel,
  IonRadioGroup,
  IonRadio,
  IonList,
  IonSearchbar,
  IonTitle,
  IonToolbar,
  modalController,
  alertController } from "@ionic/vue";
import { defineComponent } from "vue";
import { close, send } from "ionicons/icons";
import { FacilityService } from '@/services/FacilityService'
import { useStore } from "@/store";
import { ProductService } from '@/services/ProductService'
import { mapGetters } from "vuex";

export default defineComponent({
  name: "WarehouseModal",
  props: [ 'item' , 'items', 'selectedVariants'],
  data() {
    return {
      queryString: '',
      facilities: [],
      facilityId: '',
      variantItems: [] as any
    }
  },
  computed: {
    ...mapGetters({
      jobTotal: 'job/getTotal',
      getSelectedItemsToRelease: 'order/getSelectedItemsToRelease',
    }),
  },
  methods: {
    closeModal() {
      modalController.dismiss({ dismissed: true });
    },
    async saveAlert() {
      const message = this.item ? this.$t(
          'This item will be released to the warehouse you have selected.'
        )  : (this.jobTotal > 0 ? (this.jobTotal === 1 ? this.$t("There is a job already pending.")  : this.$t("There are jobs already pending.",  { count: this.jobTotal })) + " " : "") +  this.$t(
          'preorder items will be released to the warehouse you have selected.', { count: (this.selectedVariants ? this.variantItems : this.items).length }
        );
      const alert = await alertController.create({
        header: this.$t("Release orders"),
        message,
        buttons: [
            {
              text: this.$t("Cancel"),
            },
            {
              text: this.$t("Confirm"),
              handler: () => {
                (this.item ? this.releaseItemToWarehouse() : this.releaseItemsToWarehouse()).then(() => {
                    this.closeModal()
                  })
                }
              }
            ],
      });
      return alert.present();
    },
    findFacility() {
      const payload = {
        "filters":{
          "facilityName": '%' + this.queryString + '%',
          "facilityName_op": "like",
          "facilityTypeId": "WAREHOUSE",
        },
        "fieldsToSelect": ["facilityId", "facilityName"],
        "sortBy" : "facilityName ASC",
        "sortOrder" : "facilityName ASC" // TODO Remove it
      }
      this.facilityId = '';
      FacilityService.findFacility(payload).then((resp: any) => {
        if(resp.data.error) {
          this.facilities = [];
        } else {
          this.facilities = resp.data.docs;
        }
      })
    },
    releaseItemsToWarehouse: async function() {
      const items = this.selectedVariants ? this.variantItems.map((item: any) => {
        item.toFacilityId = this.facilityId
        return item;
      }) : this.getSelectedItemsToRelease(this.facilityId);
      const json = JSON.stringify(items);
      const blob = new Blob([json], { type: 'application/json'});
      const formData = new FormData();
      const fileName = "ReleaseItemsToWarehouse_" + Date.now() +".json";
      formData.append("uploadedFile", blob, fileName);
      formData.append("configId", "MDM_REL_ORD_ITM_JSON");
      return this.store.dispatch("order/releaseItems", {
          headers: {
              'Content-Type': 'multipart/form-data;'
          },
          data: formData
      }).then(() => {
        this.store.dispatch("order/removeItems", { items: this.items });
      })
    },
    async releaseItemToWarehouse () {
      return this.store.dispatch("order/releaseItem", {
          orderId: this.item.orderId,
          orderItemSeqId: this.item.orderItemSeqId,
          toFacilityId: this.facilityId
        }).then(() => {
          this.store.dispatch("order/removeItem", { item: this.item });
        })
    },
    async processSelectedVariants() {
      const variantRequests: any = [];
      Object.keys(this.selectedVariants).forEach((productId: any) => {
        const payload = {
          groupByField: 'productId',
          groupLimit: this.selectedVariants[productId],
          filters: [ "productId: " + productId , ...JSON.parse(process.env.VUE_APP_ORDER_FILTERS) ] as any,
          sortBy: "orderDate ASC"
        }
        variantRequests.push(ProductService.fetchCurrentList(payload));
      });
      Promise.all(variantRequests).then(async([...responses]) => {
        responses.forEach((response: any) => {
          const items = response.data.grouped.productId.groups[0].doclist.docs.map((item: any) => {
            return {
              orderId: item.orderId,
              orderItemSeqId: item.orderItemSeqId,
              toFacilityId: this.facilityId
            }
          })
          this.variantItems = [...this.variantItems, ...items];
        })
      })
    },
    selectSearchBarText(event: any) {
      event.target.getInputElement().then((element: any) => {
        element.select();
      })
    }
  },
  beforeMount () {
    this.findFacility();
    if (this.selectedVariants) {
      this.processSelectedVariants();
    }
  },
  setup() {
    const store = useStore();
    return {
      close,
      send,
      store
    };
  },
  components: { 
    IonButtons,
    IonButton,
    IonContent,
    IonFab,
    IonFabButton,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonRadioGroup,
    IonRadio,
    IonSearchbar,
    IonTitle,
    IonToolbar 
    },
});

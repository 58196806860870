
import { useStore } from "@/store";
import { 
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  modalController } from "@ionic/vue";
import { defineComponent } from "vue";
import { mapGetters } from 'vuex'
import TimeZoneModal from '@/views/timezone-modal.vue'

export default defineComponent({
  name: "settings",
  components: {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonItem,
    IonLabel,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar
  },
  setup() {
    const store = useStore();
    return { store }
  },
  computed: {
    ...mapGetters({
      userProfile: 'user/getUserProfile'
    })
  },
  methods: {
    logout: function() {
      this.store.dispatch("user/logout").then(() => {
        this.$router.push('/login')
      })
    },
    async changeTimeZone() {
      const timeZoneModal = await modalController.create({
        component: TimeZoneModal,
      });
      return timeZoneModal.present();
    }
  }
});

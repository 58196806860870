
import {
  IonBadge,
  IonButtons,
  IonButton,
  IonCard,
  IonChip,
  IonCheckbox,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonMenuButton,
  IonInput,
  IonNote,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonSearchbar,
  IonThumbnail,
  IonTitle,
  IonToggle,
  IonToolbar,
  IonInfiniteScroll, 
  IonInfiniteScrollContent,
  alertController,
  modalController,
  popoverController,
} from "@ionic/vue";
import { defineComponent } from "vue";
import WarehouseModal from "./warehouse-modal.vue";
import BackgroundJobModal from "./background-job-modal.vue";
import PromiseDateModal from "./promise-date-modal.vue";
import Popover from "../views/order-popover.vue";
import {
  pricetag,
  ribbon,
  ellipsisVertical,
  send,
  business,
  calendar,
  closeCircle,
  hourglass,
  close
} from "ionicons/icons";
import { useStore } from "@/store";
import { mapGetters } from "vuex";
import moment from 'moment'
import { showToast } from '@/utils'
import { Plugins } from '@capacitor/core';
import Image from '@/components/Image.vue';

const { Clipboard } = Plugins;

export default defineComponent({
  name: "orders",
  components: {
    IonBadge,
    IonButtons,
    IonButton,
    IonCard,
    IonChip,
    IonCheckbox,
    IonContent,
    IonFooter,
    IonHeader,
    IonInput,
    IonItem,
    IonIcon,
    IonLabel,
    IonMenuButton,
    IonNote,
    IonPage,
    IonSelect,
    IonSelectOption,
    IonSearchbar,
    IonThumbnail,
    IonTitle,
    IonToggle,
    IonToolbar,
    IonInfiniteScroll, 
    IonInfiniteScrollContent,
    Image
  },
  data() {
    return {
      queryString: '',
      orderIdentificationTypeId: process.env.VUE_APP_ORD_IDENT_TYPE_ID,
      orderedAfter: '',
      orderedBefore: '',
      promisedAfter: '',
      promisedBefore: '',
      cusotmerLoyaltyOptions : JSON.parse(process.env?.VUE_APP_CUST_LOYALTY_OPTIONS),
      cusotmerLoyalty: '',
      hasPromisedDate: true,
      hasQuery:  false // TODO Find a better way. We are handling empty state and result empty state differently. To identify whether a query is done added a flag variable.
    }
  },
  computed: {
    ...mapGetters({
      orders: 'order/getList',
      preorderCount: 'order/getListPreorders',
      ordersTotal: 'order/getListTotal',
      isScrolleable: 'order/isScrolleable',
      getProductStock: 'stock/getProductStock',
      getProduct: 'product/getProduct',
      isJobPending: 'job/isJobPending',
      jobTotal: 'job/getTotal',
      selectedItems: 'order/getSelectedItems',
      getSelectedItemsToRelease: 'order/getSelectedItemsToRelease',
      selectedItemsToCancel: 'order.getSelectedItemsToCancel',
      selectedItemsCount: 'order/getSelectedItemsCount',
      userProfile: 'user/getUserProfile'
    }),
  },
  methods: {
    async loadMoreOrders(event: any) {
      this.getOrders(
        undefined,
        Math.ceil(this.orders.length / process.env.VUE_APP_VIEW_SIZE).toString()
      ).then(() => {
        event.target.complete();
      })
    },
    async getOrders( vSize?: any, vIndex?: any) {
      const viewSize = vSize ? vSize : process.env.VUE_APP_VIEW_SIZE;
      const viewIndex = vIndex ? vIndex : 0;
      const payload = {
        viewSize,
        viewIndex,
        queryString: '*' + this.queryString + '*',
        queryFields: 'parentProductId productId parentProductName productName goodIdentifications orderId search_orderIdentifications customerPartyName',
        groupByField: 'orderId',
        groupLimit: -1,
        filters: JSON.parse(process.env.VUE_APP_ORDER_FILTERS)
      }
      if (this.orderedBefore || this.orderedAfter) {
        const orderedBefore = (this.orderedBefore ? moment.tz(this.orderedBefore, 'YYYY-MM-DD', this.userProfile.userTimeZone) : moment.tz(moment(), this.userProfile.userTimeZone)).endOf('day').utc().format('YYYY-MM-DDTHH:mm:ss[Z]');
        const orderedAfter = (this.orderedAfter ? moment.tz(this.orderedAfter, 'YYYY-MM-DD', this.userProfile.userTimeZone) : moment.tz("0001-01-01", 'YYYY-MM-DD', this.userProfile.userTimeZone)).startOf('day').utc().format('YYYY-MM-DDTHH:mm:ss[Z]');
        const dateQuery: any = 'orderDate: [' + orderedAfter + ' TO ' + orderedBefore + ']';
        payload.filters.push(dateQuery);
      }
      if (this.promisedBefore || this.promisedAfter) {
        const promisedBefore = (this.promisedBefore ? moment.tz(this.promisedBefore, 'YYYY-MM-DD', this.userProfile.userTimeZone) : moment.tz(moment(), this.userProfile.userTimeZone)).endOf('day').utc().format('YYYY-MM-DDTHH:mm:ss[Z]');
        const promisedAfter = (this.promisedAfter ? moment.tz(this.promisedAfter, 'YYYY-MM-DD', this.userProfile.userTimeZone) : moment.tz("0001-01-01", 'YYYY-MM-DD', this.userProfile.userTimeZone)).startOf('day').utc().format('YYYY-MM-DDTHH:mm:ss[Z]');
        const promisedDateQuery: any = 'promisedDatetime: [' + promisedAfter + ' TO ' + promisedBefore + ']';
        payload.filters.push(promisedDateQuery);
      }
      if (this.cusotmerLoyalty) {
        payload.filters.push('orderNotes: ' +this.cusotmerLoyalty);
      }
      if (!this.hasPromisedDate) {
        payload.filters.push("-promisedDatetime: *");
      }
      return this.store.dispatch("order/findOrder", payload).finally(() => this.hasQuery = true );
    },
    async releaseItems() {
      const selectedItems = this.getSelectedItemsToRelease("_NA_"); // TODO Make it configurable
      const json = JSON.stringify(selectedItems);
      const blob = new Blob([json], { type: 'application/json'});
      const formData = new FormData();
      const fileName = "ReleaseItems_" + Date.now() +".json";
      formData.append("uploadedFile", blob, fileName);
      formData.append("configId", "MDM_REL_ORD_ITM_JSON");
      this.deselectSelectedItems();
      return this.store.dispatch("order/releaseItems", {
          headers: {
              'Content-Type': 'multipart/form-data;'
          },
          data: formData
      }).then(() => {
        // TODO Find a better place to call this
        this.store.dispatch("order/removeItems", { items: selectedItems });
      })
    },
    async cancelItems() {
      const selectedItems = this.selectedItemsToCancel;
      const json = JSON.stringify(selectedItems);
      const blob = new Blob([json], { type: 'application/json'});
      const formData = new FormData();
      const fileName = "CancelItems_" + Date.now() +".json";
      formData.append("uploadedFile", blob, fileName);
      formData.append("configId", "MDM_CAN_ORD_ITM_JSON");
      this.deselectSelectedItems();
      return this.store.dispatch("order/cancelItems", {
          headers: {
              'Content-Type': 'multipart/form-data;'
          },
          data: formData
      }).then(() => {
        // TODO Find a better place to call this
        this.store.dispatch("order/removeItems", { items: selectedItems });
      })
    },
    async deselectAlert() {
      const alert = await alertController.create({
        header: this.$t("Deselect items"),
        message: this.$t('Deselect the selected order items', { count: this.selectedItems.length }),
        buttons: [
            {
              text: this.$t("Cancel"),
              role: 'cancel',
              cssClass: 'secondary'
            },
            {
              text: this.$t("Deselect"),
              handler: () => {
                this.deselectSelectedItems();
              },
            },
          ],
      });
      return alert.present();
    },
    async releaseAlert() {
      const message = (this.jobTotal > 0 ? (this.jobTotal === 1 ? this.$t("There is a job already pending.")  : this.$t("There are jobs already pending.",  { count: this.jobTotal })) + " " : "") + this.$t(
          'preorders will be automatically brokered and assigned for fulfillment.', { count: this.selectedItems.length }
        );
      const alert = await alertController.create({
        header: this.$t("Release orders"),
        cssClass: "alert-message",
        message,
        buttons: [
            {
              text: this.$t('Cancel'),
              role: 'cancel',
              cssClass: 'secondary'
            },
            {
              text:this.$t('Release'),
              handler: () => {
                this.releaseItems();
                
              },
            },
          ],
      });
      return alert.present();
    },
    async cancelAlert() {
      const message = (this.jobTotal > 0 ? (this.jobTotal === 1 ? this.$t("There is a job already pending.")  : this.$t("There are jobs already pending.",  { count: this.jobTotal })) + " " : "") + this.$t(
          'preorders will be cancelled. This action cannot be undone.',{ count: this.selectedItems.length }
        )
      const alert = await alertController.create({
        header:this.$t("Cancel orders"),
        message,
        buttons: [
            {
              text: this.$t("Don't cancel"),
              role: 'cancel',
              cssClass: 'secondary'
            },
            {
              text: this.$t("Confirm"),
              handler: () => {
                this.cancelItems();
              },
            },
          ],
      });
      return alert.present();
    },
    async openWarehouseList() {
      const warehousemodal = await modalController.create({
        component: WarehouseModal,
        cssClass: "my-custom-class",
        componentProps: {
          items: this.selectedItems
        },
      });
      warehousemodal.onDidDismiss().finally(() => {
        // TODO FIX this it should deselect only on successful action
        this.deselectSelectedItems();
      })
      return warehousemodal.present();
    },
    async openActiveJobs() {
      const bgjobmodal = await modalController.create({
        component: BackgroundJobModal,
        cssClass: "my-custom-class",
      });
      return bgjobmodal.present();
    },
    async editPromiseDate() {
      const datemodal = await modalController.create({
        component: PromiseDateModal,
        cssClass: "my-custom-class",
        componentProps: {
          items: this.selectedItems
        },
      });
      datemodal.onDidDismiss().finally(() => {
        // TODO FIX this it should deselect only on successful action
        this.deselectSelectedItems();
      })
      return datemodal.present();
    },
    selectItem: function(event: any, item: any) {
      const existingItemIndex = this.selectedItems.findIndex((element: any) => element.orderId === item.orderId && element.orderItemSeqId === item.orderItemSeqId)
      if (event.target.checked && existingItemIndex === -1) {
        this.store.dispatch("order/addToSelectedItems", { item });
      } else if(!event.target.checked && existingItemIndex > -1) {
        this.store.dispatch("order/removeFromSelectedItems", { index: existingItemIndex });
      }
    },
    deselectSelectedItems: function() {
      this.selectedItems.forEach((item: any) => {
          item.isChecked = false;
      })
    },
    async openPopover(ev: Event, item: any) {
      const popover = await popoverController.create({
        component: Popover,
        componentProps: {
            "item": item
        },
        event: ev,
        translucent: true,
        showBackdrop: false,
      });
      return popover.present();
    },
    async copyToClipboard(text: string) {
      await Clipboard.write({
        string: text
      }).then(() => {
        showToast(this.$t('Copied', { text }));
      })
    },
    selectSearchBarText(event: any) {
      event.target.getInputElement().then((element: any) => {
        element.select();
      })
    }
  },
  setup() {
    const store = useStore();
    return {
      store,
      pricetag,
      ribbon,
      ellipsisVertical,
      send,
      business,
      calendar,
      closeCircle,
      hourglass,
      close,
    };
  },
});


import { 
  IonButton,
  IonContent,
  IonInput,
  IonItem,
  IonLabel,
  IonPage } from "@ionic/vue";
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "./../store";

export default defineComponent({
  name: "login",
  components: {
    IonButton,
    IonContent,
    IonInput,
    IonItem,
    IonLabel,
    IonPage
  },
  data() {
    return {
      username: "",
      password: ""
    };
  },
  methods: {
    login: function () {
      const { username, password } = this;
      this.store.dispatch("user/login", { username, password }).then((data: any) => {
        if (data.token) {
          this.username = ''
          this.password = ''
          this.$router.push('/')
        }
      }).catch((error: any) => {
        console.error("error", error);
      })
    }
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    return { router, store };
  }
});

import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import { Module } from 'vuex'
import OrderState from './OrderState'
import RootState from '../../RootState'

const orderModule: Module<OrderState, RootState> = {
    namespaced: true,
    state: {
      list: {
        items: [],
        total: 0,
        preorderCount: 0
      },
      selectedItems: []
    },
    getters,
    actions,
    mutations,
}

export default orderModule;

// TODO
// store.registerModule('order', orderModule);

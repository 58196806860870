
import {
  IonBadge,
  IonButton,
  IonButtons,
  IonBackButton,
  IonCard,
  IonChip,
  IonContent,
  IonFooter,
  IonHeader,
  IonLabel,
  IonList,
  IonListHeader,
  IonIcon,
  IonItem,
  IonInput,
  IonPage,
  IonRow,
  IonThumbnail,
  IonTitle,
  IonToggle,
  IonSelect,
  IonSelectOption,
  IonToolbar,
  alertController,
  modalController,
} from "@ionic/vue";
import { defineComponent } from "vue";
import {
  informationCircle,
  send,
  business,
  closeCircle,
  hourglass,
  calendar,
  close,
  list,
  ribbon
} from "ionicons/icons";
import WarehouseModal from "./warehouse-modal.vue";
import BackgroundJobModal from "./background-job-modal.vue";
import { useStore } from "@/store";
import { mapGetters } from "vuex";
import { ProductService } from '@/services/ProductService'
import moment from 'moment';
import Image from '@/components/Image.vue';

export default defineComponent({
  name: "product-details",
  components: {
    IonBadge,
    IonButton,
    IonButtons,
    IonBackButton,
    IonCard,
    IonChip,
    IonContent,
    IonFooter,
    IonHeader,
    IonLabel,
    IonList,
    IonListHeader,
    IonIcon,
    IonItem,
    IonInput,
    IonPage,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonThumbnail,
    IonTitle,
    IonToggle,
    IonToolbar,
    Image
  },
  beforeMount () {
    // TODO Handle if product id is invalid
    this.store.dispatch('product/loadCurrent', { productId: this.$route.params.id }).then(() => {
      this.getVariantProducts();
    })
  },
  data() {
    return {
      orderedAfter: '',
      orderedBefore: '',
      promisedAfter: '',
      promisedBefore: '',
      selectedItems: [] as any,
      selectedVariants: {} as any,
      cusotmerLoyaltyOptions : JSON.parse(process.env?.VUE_APP_CUST_LOYALTY_OPTIONS),
      cusotmerLoyalty: '',
      hasPromisedDate: true
    }
  },
  computed: {
    ...mapGetters({
      getProductStock: 'stock/getProductStock',
      current: 'product/getCurrent',
      getProduct: 'product/getProduct',
      isJobPending: 'job/isJobPending',
      jobTotal: 'job/getTotal',
      userProfile: 'user/getUserProfile'
    })
  },
  methods: {
    async getVariantProducts() {
      const payload = {
        groupByField: 'productId',
        groupLimit: 0,
        filters: [ "parentProductId: " + this.$route.params.id, ...JSON.parse(process.env.VUE_APP_ORDER_FILTERS) ] as any
      }
      if (this.orderedBefore || this.orderedAfter) {
        const orderedBefore = (this.orderedBefore ? moment.tz(this.orderedBefore, 'YYYY-MM-DD', this.userProfile.userTimeZone) : moment.tz(moment(), this.userProfile.userTimeZone)).endOf('day').utc().format('YYYY-MM-DDTHH:mm:ss[Z]');
        const orderedAfter = (this.orderedAfter ? moment.tz(this.orderedAfter, 'YYYY-MM-DD', this.userProfile.userTimeZone) : moment.tz("0001-01-01", 'YYYY-MM-DD', this.userProfile.userTimeZone)).startOf('day').utc().format('YYYY-MM-DDTHH:mm:ss[Z]');
        const dateQuery: any = 'orderDate: [' + orderedAfter + ' TO ' + orderedBefore + ']';
        payload.filters.push(dateQuery);
      }
      if (this.promisedBefore || this.promisedAfter) {
        const promisedBefore = (this.promisedBefore ? moment.tz(this.promisedBefore, 'YYYY-MM-DD', this.userProfile.userTimeZone) : moment.tz(moment(), this.userProfile.userTimeZone)).endOf('day').utc().format('YYYY-MM-DDTHH:mm:ss[Z]');
        const promisedAfter = (this.promisedAfter ? moment.tz(this.promisedAfter, 'YYYY-MM-DD', this.userProfile.userTimeZone) : moment.tz("0001-01-01", 'YYYY-MM-DD', this.userProfile.userTimeZone)).startOf('day').utc().format('YYYY-MM-DDTHH:mm:ss[Z]');
        const promisedDateQuery: any = 'promisedDatetime: [' + promisedAfter + ' TO ' + promisedBefore + ']';
        payload.filters.push(promisedDateQuery);
      }
      if (this.cusotmerLoyalty) {
        payload.filters.push('orderNotes: ' +this.cusotmerLoyalty);
      }
      if (!this.hasPromisedDate) {
        payload.filters.push("-promisedDatetime: *");
      }
      return this.store.dispatch("product/fetchCurrentList", payload)
    },
    async infoAlert() {
      const alert = await alertController.create({
        header: this.$t("How are orders released?"),
        message: this.$t(
          "Orders are released from oldest to newest. Use the Date filter to identify orders taken before a certain date.")+"<p>"+ this.$t("The order count in product cards will be updated based on the filters you select.")+"</p>"
        ,
        buttons: [this.$t("Dismiss")],

      });
      return alert.present();
    },
    async releaseAlert() {
      const itemCount = Object.keys(this.selectedVariants).reduce( (count: number, productId: any) => {
        return count + parseInt(this.selectedVariants[productId]);
      }, 0)
      const message = (this.jobTotal > 0 ? (this.jobTotal === 1 ? this.$t("There is a job already pending.")  : this.$t("There are jobs already pending.",  { count: this.jobTotal })) + " " : "") + this.$t(
          'preorders will be automatically brokered and assigned for fulfillment.',{ count: itemCount }
        )
      const alert = await alertController.create({
        header: this.$t("Release orders"),
        cssClass: "alert-message",
        message,
        buttons: [
          {
            text: this.$t('Cancel'),
            role: 'cancel',
            cssClass: 'secondary'
          },
          {
            text:this.$t('Release'),
            handler: () => {
              this.releaseItems();           
            },
          },
        ],
      });
      return alert.present();
    },
    async cancelAlert() {
      const itemCount = Object.keys(this.selectedVariants).reduce( (count: number, productId: any) => {
        return count + parseInt(this.selectedVariants[productId]);
      }, 0);
      const message = (this.jobTotal > 0 ? (this.jobTotal === 1 ? this.$t("There is a job already pending.")  : this.$t("There are jobs already pending.",  { count: this.jobTotal })) + " " : "") + this.$t(
          'preorders will be cancelled. This action cannot be undone.',{ count: itemCount }
        )
      const alert = await alertController.create({
        header: this.$t("Cancel orders"),
        message,
        buttons: [
            {
              text: this.$t("Don't cancel"),
              role: 'cancel',
              cssClass: 'secondary'
            },
            {
              text: this.$t("Confirm"),
              handler: () => {
                this.cancelItems();
              },
            },
          ],

      });
      return alert.present();
    },
    async openWarehouseList() {
      const warehousemodal = await modalController.create({
        component: WarehouseModal,
        cssClass: "my-custom-class",
        componentProps: {
          items: [],
          selectedVariants: this.selectedVariants
        },
      });
      return warehousemodal.present();
    },
    async openActiveJobs() {
      const bgjobmodal = await modalController.create({
        component: BackgroundJobModal,
        cssClass: "my-custom-class",
      });
      return bgjobmodal.present();
    },
    selectVariant(productId: string, quantity: string) {
      if (quantity) {
        this.selectedVariants[productId] = quantity;
      } else {
        delete this.selectedVariants[productId]
      }
    },
    async releaseItems() {
      const selectedItemsResponse = await this.processSelectedVaiants("orderDate ASC");
      let selectedItems = [] as any;
      selectedItemsResponse.forEach((response: any) => {
          const items = response.data.grouped.productId.groups[0].doclist.docs.map((item: any) => {
            return {
              orderId: item.orderId,
              orderItemSeqId: item.orderItemSeqId,
              toFacilityId: "_NA_" // TODO Make it configurable
            }
          })
          selectedItems = [...selectedItems, ...items];
      })
      const json = JSON.stringify(selectedItems);
      const blob = new Blob([json], { type: 'application/json'});
      const formData = new FormData();
      const fileName = "ReleaseItems_" + Date.now() +".json";
      formData.append("uploadedFile", blob, fileName);
      formData.append("configId", "MDM_REL_ORD_ITM_JSON");
      return this.store.dispatch("order/releaseItems", {
          headers: {
              'Content-Type': 'multipart/form-data;'
          },
          data: formData
      }).then(() => {
        this.store.dispatch("order/removeItems", { items: selectedItems });
      })
    },
    async cancelItems() {
      const selectedItemsResponse = await this.processSelectedVaiants("orderDate DESC");
      let selectedItems = [] as any;
      selectedItemsResponse.forEach((response: any) => {
          const items = response.data.grouped.productId.groups[0].doclist.docs.map((item: any) => {
            return {
              orderId: item.orderId,
              orderItemSeqId: item.orderItemSeqId
            }
          })
          selectedItems = [...selectedItems, ...items];
      })
      const json = JSON.stringify(selectedItems);
      const blob = new Blob([json], { type: 'application/json'});
      const formData = new FormData();
      const fileName = "CancelItems_" + Date.now() +".json";
      formData.append("uploadedFile", blob, fileName);
      formData.append("configId", "MDM_CAN_ORD_ITM_JSON");
      return this.store.dispatch("order/cancelItems", {
          headers: {
              'Content-Type': 'multipart/form-data;'
          },
          data: formData
      }).then(() => {
        this.store.dispatch("order/removeItems", { items: selectedItems });
      })
    },
    async processSelectedVaiants(sortBy: string) {
      const variantRequests: any = [];
      Object.keys(this.selectedVariants).forEach((productId: any) => {
        const payload = {
          groupByField: 'productId',
          groupLimit: this.selectedVariants[productId],
          filters: [ "productId:" + productId, ...JSON.parse(process.env.VUE_APP_ORDER_FILTERS) ] as any,
          sortBy: sortBy
        }
        if (this.orderedBefore || this.orderedAfter) {
          const orderedBefore = (this.orderedBefore ? moment.tz(this.orderedBefore, 'YYYY-MM-DD', this.userProfile.userTimeZone) : moment.tz(moment(), this.userProfile.userTimeZone)).endOf('day').utc().format('YYYY-MM-DDTHH:mm:ss[Z]');
          const orderedAfter = (this.orderedAfter ? moment.tz(this.orderedAfter, 'YYYY-MM-DD', this.userProfile.userTimeZone) : moment.tz("0001-01-01", 'YYYY-MM-DD', this.userProfile.userTimeZone)).startOf('day').utc().format('YYYY-MM-DDTHH:mm:ss[Z]');
          const dateQuery: any = 'orderDate: [' + orderedAfter + ' TO ' + orderedBefore + ']';
          payload.filters.push(dateQuery);
        }
        if (this.promisedBefore || this.promisedAfter) {
          const promisedBefore = (this.promisedBefore ? moment.tz(this.promisedBefore, 'YYYY-MM-DD', this.userProfile.userTimeZone) : moment.tz(moment(), this.userProfile.userTimeZone)).endOf('day').utc().format('YYYY-MM-DDTHH:mm:ss[Z]');
          const promisedAfter = (this.promisedAfter ? moment.tz(this.promisedAfter, 'YYYY-MM-DD', this.userProfile.userTimeZone) : moment.tz("0001-01-01", 'YYYY-MM-DD', this.userProfile.userTimeZone)).startOf('day').utc().format('YYYY-MM-DDTHH:mm:ss[Z]');
          const promisedDateQuery: any = 'promisedDatetime: [' + promisedAfter + ' TO ' + promisedBefore + ']';
          payload.filters.push(promisedDateQuery);
        }
        if (this.cusotmerLoyalty) {
          payload.filters.push('orderNotes: ' +this.cusotmerLoyalty);
        }
        if (!this.hasPromisedDate) {
          payload.filters.push("-promisedDatetime: *");
        }
        variantRequests.push(ProductService.fetchCurrentList(payload));
      });
      return Promise.all(variantRequests);
    },
  },
  setup() {
    const store = useStore();
    return {
      informationCircle,
      send,
      business,
      closeCircle,
      hourglass,
      calendar,
      close,
      list,
      ribbon,
      store
    };
  },
});
